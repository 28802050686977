/* eslint-disable react-hooks/exhaustive-deps */
import SelectList from "../SelectList";
import { useEffect, useState } from "react";
import useHttpService from "../../HttpService";
import { toast } from "react-toastify";
import SuggestedLessons from "./SuggestedLessons";
import { difficultyData, countryData, getLoggedInUserId, MediaBaseURL } from '../../HelperMethods';
import LoadingSpinner from '../LoadingSpinner';
import savedImage from '../../assets/images/saved-icon.svg';
import focusImage from '../../assets/images/focus-icon.svg';
import ErrorMessage from "../../assets/images/error-mesgg.svg";
import eyeVectorImage from "../../assets/images/eye-Vector.svg";
import ShowModal from "../ShowModal";

const AddUpdateLesson = ({ lesson, refreshLessons, updateLessonDetails }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLesson, setSelectedLesson] = useState(lesson);
    const [hasFieldChanged, setHasFieldChanged] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState(0);
    const [isDescriptionSaved, setIsDescriptionSaved] = useState(true);
    const [isFocusSaved, setIsFocusSaved] = useState(true);
    const [isParagraphSaved, setIsParagraphSaved] = useState(true);
    const [isLevelSaved, setIsLevelSaved] = useState(true);
    const [titleValid, setTitleValid] = useState(false);
    const [descriptionValid, setDescriptionValid] = useState(false);
    const [focusValid, setFocusValid] = useState(false);
    const [paragraphValid, setParagraphValid] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const httpService = useHttpService();
    const [languageId, setlanguageId] = useState('');
    const [languages, setLanguages] = useState([]);
    const [fileContent, setFileContent] = useState('');
    const [textColor, setTextColor] = useState('');
    const [caretClass, setCaretClass] = useState('');

    useEffect(() => {
        if (lesson.lessonId !== "Published")
            setSelectedLesson(lesson);
        if (lesson.description !== undefined && lesson.description !== '') {
            setIsDescriptionSaved(true);
        }
        else
            setIsDescriptionSaved(false);
        if (lesson.focus !== undefined && lesson.focus !== '') {
            setIsFocusSaved(true);
        }
        else
            setIsFocusSaved(false);
        if (lesson.paragraph !== undefined && lesson.paragraph !== '') {
            setIsParagraphSaved(true);
        }
        else
            setIsParagraphSaved(false);

    }, [lesson]);

    useEffect(() => {
        if (selectedLesson.lessonId === 0)
            createLesson(lesson);
    }, [selectedLesson]);

    useEffect(() => {
        saveForm(selectedLesson);
    }, [selectedLevel])

    useEffect(() => {
        if (selectedLesson.name !== undefined && selectedLesson.name !== '') {
            setTitleValid(true);
        }
        if (selectedLesson.description !== undefined && selectedLesson.description !== '') {
            setDescriptionValid(true);
        }
        if (selectedLesson.focus !== undefined && selectedLesson.focus !== '') {
            setFocusValid(true);
        }
        if (selectedLesson.paragraph !== undefined && selectedLesson.paragraph !== '') {
            setParagraphValid(true);
        }
        if (selectedLesson.levelId !== undefined && selectedLesson.levelId !== 0) {
            setIsLevelSaved(true);
        }

    }, [])
    useEffect(() => {
        getLanguages();
        const fieldsChanged =
            selectedLesson.name !== lesson.name ||
            selectedLesson.description !== lesson.description ||
            selectedLesson.focus !== lesson.focus ||
            selectedLesson.paragraph !== lesson.paragraph ||
            selectedLesson.levelId !== lesson.levelId;

        setHasFieldChanged(fieldsChanged);

        setTitleValid(selectedLesson.name !== undefined && selectedLesson.name !== '');
        setDescriptionValid(selectedLesson.description !== undefined && selectedLesson.description !== '');
        setFocusValid(selectedLesson.focus !== undefined && selectedLesson.focus !== '');
        setParagraphValid(selectedLesson.paragraph !== undefined && selectedLesson.paragraph !== '');
        setIsLevelSaved(selectedLesson.levelId !== undefined && selectedLesson.levelId !== 0);
    }, [selectedLesson]);
    const handleCountryChange = (selectedOption) => {
    }

    useEffect(() => {
        fetch('/Words.txt')
            .then((response) => response.text())
            .then((text) => setFileContent(text))
            .catch((error) => console.error('Error fetching the file:', error));

        handleBlur();
    }, []);

    const hasNonEmptyFields = (lessonObj) => {
        return (
            lessonObj.name !== "" ||
            lessonObj.paragraph !== "" ||
            lessonObj.description !== "" ||
            lessonObj.focus !== "" ||
            lessonObj.levelId !== 0
        );
    }

    const saveForm = (lessonObj) => {
        if (lessonObj.state !== 'Published' && lessonObj.name !== undefined && lessonObj.name !== '' && lessonObj.description !== undefined && lessonObj.description !== '' && lessonObj.paragraph !== undefined && lessonObj.paragraph !== '' && lessonObj.focus !== undefined && lessonObj.focus !== '' && lessonObj.levelId !== undefined) {
            lessonObj.state = 'unpublished';
        }
        if (!hasFieldChanged)
            return;
        lessonObj.lessonId = selectedLesson.lessonId;
        const shouldHitAPI = hasNonEmptyFields(lessonObj)
        if (shouldHitAPI) {
            setIsLoading(true);
            httpService.post('/api/lessons', lessonObj)
                .then(res => {
                    lesson.lessonId = res.data;
                    if (selectedLesson.description !== undefined && selectedLesson.description !== '') {
                        setIsDescriptionSaved(true);
                    }
                    else
                        setIsDescriptionSaved(false);
                    if (selectedLesson.focus !== undefined && selectedLesson.focus !== '') {
                        setIsFocusSaved(true);
                    }
                    else
                        setIsFocusSaved(false);
                    if (selectedLesson.paragraph !== undefined && selectedLesson.paragraph !== '') {
                        setIsParagraphSaved(true);
                    }
                    else
                        setIsParagraphSaved(false);
                    const updatedLesson = { ...lessonObj, lessonId: res.data };
                    setSelectedLesson(updatedLesson);
                    updateLessonDetails(updatedLesson);
                })
                .catch(err => {
                    if (err?.response?.data) {
                        setErrorMessage(err.response.data);
                        setShowModal(true);
                    } else if (err.errors) {
                        setErrorMessage(err.errors[0]);
                        setShowModal(true);
                    } else {
                        setErrorMessage("Something went wrong, please try again or contact support!");
                        setShowModal(true);
                    }
                })
                .finally(() => setIsLoading(false));
        }
    };

    const createLesson = (lessonObj) => {
        if (lessonObj.lessonId > 0)
            return;

        setIsLoading(true)
        httpService.post('/api/lessons', lessonObj)
            .then(res => {
                setSelectedLesson({ ...selectedLesson, lessonId: res.data });
                const updatedLesson = { ...lessonObj, lessonId: res.data };
                updateLessonDetails(updatedLesson);
                refreshLessons("New");
            })
            .catch(err => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setShowModal(true);
                } else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setShowModal(true);
                } else {
                    setErrorMessage("Something went wrong, please try again or contact support!");
                    setShowModal(true);
                }
            })
            .finally(() => setIsLoading(false));
    }

    const handleState = (state, updateLesson) => {
        const userId = getLoggedInUserId();
        if (state === 'Published') {
            const errors = [];
            if (selectedLesson.name === '') {
                errors.push('Title is required!');
            }
            if (selectedLesson.description === '') {
                errors.push('Description is required!');
            }
            if (selectedLesson.focus === '') {
                errors.push('Focus is required!');
            }
            if (selectedLesson.levelId < 1) {
                errors.push('Difficulty is required!');
            }
            if (selectedLesson.paragraph === '') {
                errors.push('Paragraph is required!');
            }
            if (errors.length > 0) {
                const validation = errors.map((error, index) => (
                    <p key={index}>{`${index + 1}. ${error}`}</p>
                ));
                setErrorMessage(validation);
                setShowModal(true);
                return;
            }
        }
        if (updateLesson === false)
            setIsLoading(true);
        httpService.put(`/api/lessons/update-state?userId=${userId}&lessonId=${lesson.lessonId}&state=${state}`)
            .then(res => {
                if (updateLesson === false)
                    toast.success(`Lesson ${state}ed successfully.`);
                refreshLessons(state);
            })
            .catch(err => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setShowModal(true);
                } else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setShowModal(true);
                } else {
                    setErrorMessage("Something went wrong, please try again or contact support!");
                    setShowModal(true);
                }
            })
            .finally(() => setIsLoading(false));
    }

    const handleDelete = (event) => {
        event.preventDefault();

        httpService.remove(`/api/lessons?lessonId=${lesson.lessonId}`)
            .then(res => {
                toast.success(`Lesson deleted successfully.`);
                refreshLessons("Deleted");
            })
            .catch(err => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setShowModal(true);
                } else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setShowModal(true);
                } else {
                    setErrorMessage("Something went wrong, please try again or contact support!");
                    setShowModal(true);
                }
            });
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleStartLesson = (lessonId, e) => {
        e.preventDefault();
        const userId = getLoggedInUserId();
        httpService.post(`/api/userLessons/join?userId=${userId}&lessonId=${lessonId}`)
            .then(res => {
                const newTab = window.open('', '_blank');
                newTab.location.href = '/userLesson/' + res.data;
            })
            .catch(err => {
                setErrorMessage('Something went wrong please try again!');
                setShowModal(true);
            })
    };

    const getLanguages = () => {
        httpService
            .get(`/api/languages/all`)
            .then((res) => {
                const transformedData = convertApiDataToOptions(res.data);
                setLanguages(transformedData);
            })
            .catch((err) => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                }
                else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                }
                else {
                    setErrorMessage("Something went wrong, please try again or contact support!");
                }
                setShowModal(true);
                return;
            })
            .finally();
    };
    const convertApiDataToOptions = (apiData) => {
        return apiData.map((language) => ({
            value: String(language.languageId),
            label: language.name,
            image: `${MediaBaseURL}${language.flagPath}`,
        }));
    };

    const checkWordsInFile = (value) => {
        const wordsArray = value.trim().split(/\s+/);

        if (!Array.isArray(fileContent)) {
            return false;
        }

        return fileContent.some(line => {
            return wordsArray.every(word => line.includes(word));
        });
    };

    const handleBlur = (e) => {
        const value = (e ? e.target.value : selectedLesson.description).trim();
        setHasFieldChanged(true);
        saveForm(selectedLesson);
        setDescriptionValid(value !== '');

        if (value !== '') {
            const wordsExist = checkWordsInFile(value);
            setTextColor(wordsExist ? '#FFFF00' : '#000000');
            setCaretClass('');
        } else {
            setTextColor('#000000');
            setCaretClass('');
        }
    };

    const handleChange = (e) => {
        setSelectedLesson((prevLesson) => ({
            ...prevLesson,
            description: e.target.value,
        }));
        setIsDescriptionSaved(false);
        setDescriptionValid(true);
        setCaretClass('caret-black');
    };
    return (
        <>
            <div className="lesson-title-right lesson-dashbord-right zxc">
                <div className="sticky-right">
                    <div className="sticky-scroll">
                        <div className="lesson-languages-main">
                            <div className="form-floating input-field-left mb-3 lesson-left" style={{ border: ` ${titleValid ? '0px solid #CDCDCD' : ' 1px solid red'}`, borderRadius: '7px' }} >
                                <input autoComplete="off" type="text" className='form-control' id="floatingInputS15" placeholder="" value={selectedLesson.name} onChange={(e) => { setSelectedLesson((prevLesson) => ({ ...prevLesson, name: e.target.value })); setTitleValid(true); }} onBlur={(e) => { setHasFieldChanged(true); saveForm(selectedLesson); setTitleValid(e.target.value.trim() !== '') }} />
                                <label htmlFor="floatingInputS15">Lesson Title</label>
                            </div>
                            <div className="difficulty-dp">
                                <SelectList
                                    data={languages}
                                    handleSelectChange={(selectedOption) => {
                                        setHasFieldChanged(true);
                                        setSelectedLesson((prevLesson) => ({ ...prevLesson, languageId: selectedOption.value }));
                                        saveForm({ ...selectedLesson, languageId: selectedOption.value });
                                    }}
                                    placeHolder="Select Language"
                                    defaultSelectedOption={languages.find(lang => lang.value === selectedLesson.languageId)}
                                    className="form-select form-select-lg mb-3"
                                />

                            </div>
                        </div>
                        <div className="lesson-description-main">
                            <div className="description-left">
                                <div className='description-block' style={{ border: `1px solid ${descriptionValid ? '#CDCDCD' : 'red'}` }}>
                                    <label>Description</label>
                                    <textarea
                                        className="dashbord-textarea p-20"
                                        onBlur={(e) => { setHasFieldChanged(true); saveForm(selectedLesson); setDescriptionValid(e.target.value.trim() !== '') }}
                                        onChange={(e) => { setSelectedLesson((prevLesson) => ({ ...prevLesson, description: e.target.value })); setIsDescriptionSaved(false); setDescriptionValid(true) }}
                                        value={selectedLesson.description}
                                    />
                                    <div className="desc-saved">
                                        <img src={isDescriptionSaved ? savedImage : focusImage} alt="" />
                                        <span>{isDescriptionSaved ? 'Saved Successfully' : 'Not Saved'}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="focus-right">
                                <div className='focus-block' style={{ border: `1px solid ${focusValid ? (selectedLesson.focus.trim() === '-' ? 'red' : '#CDCDCD') : 'red'}` }}>
                                    <label>Focus</label>
                                    <textarea
                                        className="p-20"
                                        autoComplete="off"
                                        onBlur={(e) => { setHasFieldChanged(true); saveForm(selectedLesson); setFocusValid(e.target.value.trim() !== '') }}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            const hasHyphenAtStart = newValue.startsWith('- ');

                                            setSelectedLesson((prevLesson) => ({
                                                ...prevLesson,
                                                focus: hasHyphenAtStart ? newValue : `- ${newValue}`,
                                            }));
                                            setIsFocusSaved(false);
                                            setFocusValid(true);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                setSelectedLesson((prevLesson) => ({
                                                    ...prevLesson,
                                                    focus: prevLesson.focus + '\n- ',
                                                }));
                                            }
                                        }}
                                        value={selectedLesson.focus}
                                    />
                                    <div className="focus-saved">
                                        <img src={isFocusSaved ? savedImage : focusImage} alt="" />
                                        <span>{isFocusSaved ? 'Saved Successfully' : 'Not Saved'}</span>
                                    </div>
                                </div>
                                <SelectList
                                    data={difficultyData}
                                    handleSelectChange={(selectedOption) => {
                                        setHasFieldChanged(true);
                                        setSelectedLevel(selectedOption.value);
                                        setSelectedLesson((prevLesson) => ({ ...prevLesson, levelId: selectedOption.value }));
                                        setIsLevelSaved(false);
                                    }}
                                    placeHolder="Select Difficulty"
                                    defaultSelectedOption={difficultyData[selectedLesson.levelId - 1]}
                                    swipeLabel={true}
                                    hasRedBorder={true}
                                />
                            </div>
                        </div>
                        <div className="description-block" style={{ border: `1px solid ${paragraphValid ? '#CDCDCD' : 'red'}` }}>
                            <label>Lesson Paragraph</label>
                            <div className="paragraph">
                                <textarea
                                    className="p-20"
                                    autoComplete="off"
                                    onBlur={(e) => { setHasFieldChanged(true); saveForm(selectedLesson); setParagraphValid(e.target.value.trim() !== '') }}
                                    onChange={(e) => { setSelectedLesson((prevLesson) => ({ ...prevLesson, paragraph: e.target.value })); setIsParagraphSaved(false); setParagraphValid(true) }}
                                    value={selectedLesson.paragraph}
                                />
                                <div className="desc-saved">
                                    <img src={isParagraphSaved ? savedImage : focusImage} alt="" />
                                    <span>{isParagraphSaved ? 'Saved Successfully' : 'Not Saved'}</span>
                                </div>
                            </div>
                        </div>
                        <div className="lesson-parabox-notsaved">
                            <img src={isLevelSaved ? savedImage : focusImage} alt="" />
                            <span>{isLevelSaved ? 'Saved Successfully' : 'Not Saved'}</span>
                        </div>
                        {lesson && lesson.lessonId > 0 && <SuggestedLessons lessonId={lesson.lessonId} />}
                        {lesson.lessonId > 0 && (
                            <a href="/" className="view-lesson-icon" target="_blank" onClick={(e) => { handleStartLesson(lesson.lessonId, e) }}>
                                <div className="view-lesson-left">
                                    <img src={eyeVectorImage} alt="" />
                                </div>
                                <div className="view-lesson-right">
                                    <a href="view-lesson-linkk">View Lesson</a>
                                </div>
                            </a>
                        )}
                        <div className="del-publish-buttons">
                            <div className="del-btn">
                                <a href="/" onClick={(event) => { handleDelete(event) }}>Delete</a>
                            </div>
                            <div className="publish-unbublish-btns">
                                <a href="/" className="un-publish" onClick={(event) => { event.preventDefault(); handleState('Unpublished', false) }}>Unpublish</a>
                                <a href="/" className="publish" onClick={(event) => { event.preventDefault(); handleState('Published', false) }}>Publish</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {isLoading && <LoadingSpinner />}
            {showModal && (
                <ShowModal showModal={showModal} handleCloseModal={handleCloseModal} AddClass={'error-message-mainbox fade'}>
                    <div className="error-mesg-main">
                        <div className="del-box">
                            <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <div className="error-summary">
                                <div className="error-summary-two">
                                    {errorMessage}
                                </div>
                            </div>
                            <div className="continoue">
                                <a href="/" onClick={(e) => {
                                    e.preventDefault();
                                    setShowModal(false);
                                }}>Continue</a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )
            }
        </>
    )
}

export default AddUpdateLesson;